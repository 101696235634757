import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { IconChevronDown } from "@tabler/icons-react";

const pageData = [
  { id: 1, name: "10", value: 10 },
  { id: 2, name: "15", value: 15 },
  { id: 3, name: "20", value: 20 },
];

const NavTable = ({
  prev,
  next,
  disabledPrev,
  disabledNext,
  changePerPage,
  data,
}) => {
  const [perPage, setPerPage] = useState(pageData[0]);

  const onChangePerPage = (params) => {
    setPerPage(params);
    changePerPage(params.value);
  };

  if (Boolean(!data.length)) {
    return (
      <div className="flex items-center border-t border-gray-200 justify-between shadow ring-1 ring-black ring-opacity-5 rounded-b-lg bg-white py-3 px-6 h-[60px]" />
    );
  }

  return (
    <nav
      className="flex items-center border-t border-gray-200 justify-between shadow ring-1 ring-black ring-opacity-5 rounded-b-lg bg-white py-3 px-3 lg:px-6 gap-6"
      aria-label="Pagination"
    >
      <div className="flex-1 hidden lg:block" />
      <div className="flex flex-row border border-gray-300 rounded-lg items-center px-2 justify-between h-[35px]">
        <span className="text-sm text-gray-600">Per Page</span>
        <div className="border-l border-gray-300 h-[35px] mx-2" />
        <Listbox value={perPage} onChange={onChangePerPage}>
          <div className="relative">
            <Listbox.Button className="flex flex-row items-center gap-1 relative cursor-default bg-white py-1 px-1 text-left text-sm w-[42px]">
              <span className="block w-[14px]">{perPage.name}</span>
              <span className="pointer-events-none flex items-center">
                <IconChevronDown
                  className="h-3.5 w-3.5 text-black-primary"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="max-w-[55px] w-[55px] -left-[8px] absolute mt-2 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {pageData.map((person, personIdx) => (
                  <Listbox.Option
                    key={personIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 text-center ${
                        active ? "bg-secondary text-white" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ perPage }) => (
                      <>
                        <span
                          className={`block truncate ${
                            perPage ? "font-medium" : "font-normal"
                          }`}
                        >
                          {person.name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="flex flex-1 justify-end">
        {!disabledPrev && (
          <button
            onClick={prev}
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </button>
        )}
        {!disabledNext && (
          <button
            onClick={next}
            className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Next
          </button>
        )}
      </div>
    </nav>
  );
};

export default NavTable;
