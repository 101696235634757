import React from "react";
import TableResults from "./DashboardComponent/tableResults";

const Completed = () => {
  return (
    <div className="mt-8 pb-40">
      <h1 className="text-xl font-bold tracking-tight text-gray-950">
        Completed
      </h1>
      <div className="flex flex-col gap-y-8 mt-6">
        <TableResults />
      </div>
    </div>
  );
};

export default Completed;
