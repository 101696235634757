import React, { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import { API_URL, get } from "lib/network/api";
import moment from "moment";
import toast from "react-hot-toast";
import CurrencyFormatter from "lib/CurrencyFormatter";
import findNameData from "lib/FindNameMasterData";
import { useSelector } from "react-redux";
import NavTable from "./navTable";
import ModalDetails from "./modalDetails";
import ModalInfo from "./modalInfo";
import TableHandle from "./tableHandle";

const TableResults = () => {
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const masterData = useSelector((state) => state.MasterData.data);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [detailId, setDetailId] = useState(null);
  const [infoId, setInfoId] = useState(null);

  const formattedDate = (dateString) => {
    return moment(dateString).format("MMM DD, YYYY");
  };

  const FetchResults = () => {
    setLoading(true);
    get(
      `${API_URL}ldr/loan/offerings?page=${page}&page_size=${perPage}&status=COMPLETED`
    )
      .then((res) => {
        setData(res.data);
        setLastPage(res.metadata.last_page ? res.metadata.last_page : 1);
        setPage(res.metadata.current_page ? res.metadata.current_page : 1);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page !== lastPage) {
      setPage(page + 1);
    }
  };

  const openDetail = (paramsId) => {
    setOpen(true);
    setDetailId(paramsId);
  };

  const openInfoModal = (paramsId) => {
    setOpenInfo(true);
    setInfoId(paramsId);
  };

  return (
    <div className="flow-root">
      <div>
        <div className="shadow ring-1 ring-black ring-opacity-5 rounded-t-lg overflow-hidden">
          <div className="py-4 px-6 bg-white border-b border-gray-200 flex flex-col">
            <span className="font-semibold text-sm">Completed Offers</span>
            {/* <span className="text-sm text-gray-500">
              Tip: Click on 1 of the rows
            </span> */}
          </div>
          <div className="overflow-x-auto costume-scrollbar">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden ">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                      >
                        Loan Amount
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                      >
                        Tenor Duration
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                      >
                        Monthly Cost
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                      >
                        Monthly Rate
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      ></th>
                    </tr>
                  </thead>
                  <TableHandle
                    loading={loading}
                    loadingComponent={
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {[...Array(10)].map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="whitespace-nowrap py-4 pl-6 pr-3 text-xs font-medium text-gray-900 ">
                                <Typography
                                  key={idx}
                                  as="div"
                                  variant="h1"
                                  className="h-[15px] rounded-sm  bg-gray-300"
                                >
                                  &nbsp;
                                </Typography>
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-xs font-medium text-gray-900 ">
                                <Typography
                                  key={idx}
                                  as="div"
                                  variant="h1"
                                  className="h-[15px] rounded-sm  bg-gray-300"
                                >
                                  &nbsp;
                                </Typography>
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-xs font-medium text-gray-900 ">
                                <Typography
                                  key={idx}
                                  as="div"
                                  variant="h1"
                                  className="h-[15px] rounded-sm  bg-gray-300"
                                >
                                  &nbsp;
                                </Typography>
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-xs font-medium text-gray-900 ">
                                <Typography
                                  key={idx}
                                  as="div"
                                  variant="h1"
                                  className="h-[15px] rounded-sm  bg-gray-300"
                                >
                                  &nbsp;
                                </Typography>
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-xs font-medium text-gray-900 ">
                                <Typography
                                  key={idx}
                                  as="div"
                                  variant="h1"
                                  className="h-[15px] rounded-sm  bg-gray-300"
                                >
                                  &nbsp;
                                </Typography>
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-xs font-medium text-gray-900 ">
                                <Typography
                                  key={idx}
                                  as="div"
                                  variant="h1"
                                  className="h-[15px] rounded-sm  bg-gray-300"
                                >
                                  &nbsp;
                                </Typography>
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-xs font-medium text-gray-900 ">
                                <Typography
                                  key={idx}
                                  as="div"
                                  variant="h1"
                                  className="h-[15px] rounded-sm  bg-gray-300"
                                >
                                  &nbsp;
                                </Typography>
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-xs font-medium text-gray-900 ">
                                <Typography
                                  key={idx}
                                  as="div"
                                  variant="h1"
                                  className="h-[15px] rounded-sm  bg-gray-300"
                                >
                                  &nbsp;
                                </Typography>
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-xs font-semibold max-w-32">
                                <Typography
                                  key={idx}
                                  as="div"
                                  variant="h1"
                                  className="h-[15px] rounded-sm  bg-gray-300"
                                >
                                  &nbsp;
                                </Typography>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    }
                    data={data}
                    dataComponent={
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {data.map((item, idx) => (
                          <tr key={idx}>
                            <td className="whitespace-nowrap py-4 pr-3 pl-6 text-xs font-medium text-gray-900">
                              {item.created_at
                                ? formattedDate(item.created_at)
                                : "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-900">
                              {item.loan_application
                                ? item.loan_application.full_name
                                : "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-900">
                              {item.loan_application
                                ? item.loan_application.phone_number
                                : "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-900">
                              {item.loan_application
                                ? item.loan_application.email
                                : "-"}
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-900">
                              {item.approved_loan_amount
                                ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                    item.approved_loan_amount
                                  )
                                : "-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-900">
                              {item?.tenor_id
                                ? `${findNameData(
                                    masterData,
                                    "tenor",
                                    item?.tenor_id
                                  )} months`
                                : "-"}
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-900">
                              {item.monthly_cost
                                ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                    item.monthly_cost
                                  )
                                : "$0"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-900">
                              {item.monthly_rate
                                ? `${item.monthly_rate}%`
                                : "0%"}
                            </td>

                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-xs font-semibold">
                              <div className="gap-x-2 flex flex-row justify-end">
                                <span
                                  onClick={() =>
                                    openDetail(item.loan_application_id)
                                  }
                                  className="text-primary hover:text-secondary cursor-pointer"
                                >
                                  Detail
                                </span>
                                <span
                                  onClick={() =>
                                    openInfoModal(item.loan_application_id)
                                  }
                                  className="text-primary hover:text-secondary cursor-pointer"
                                >
                                  Info
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    }
                    colspan="9"
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
        <NavTable
          prev={handlePrevPage}
          next={handleNextPage}
          disabledPrev={page === 1}
          disabledNext={page === lastPage}
          changePerPage={setPerPage}
          data={data}
        />
      </div>
      <ModalDetails
        open={open}
        setOpen={setOpen}
        id={detailId}
        masterData={masterData}
      />
      <ModalInfo
        open={openInfo}
        setOpen={setOpenInfo}
        id={infoId}
        masterData={masterData}
      />
    </div>
  );
};

export default TableResults;
