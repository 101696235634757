import React from 'react';

import Login from 'pages/Auth/login';
import Dashboard from 'pages/dashboard';
import Profile from 'pages/profile';
import OfferedPending from 'pages/offeredPending';
import Appointments from 'pages/appointments';
import Completed from "pages/completed";

const publicRoutes = [
  { path: '/login', element: <Login /> },
  { path: '/profile', element: <Profile /> },
]

const appRoutes = [
  { path: '/', element: <Dashboard /> },
  { path: '/offered', element: <OfferedPending /> },
  { path: '/appointments', element: <Appointments /> },
  { path: '/completed', element: <Completed /> },
];

export { appRoutes, publicRoutes };