import React from "react";
import TableAcceptedOffers from "./DashboardComponent/tableOffersAccepted";

const Appointments = () => {
  return (
    <div className="mt-8 pb-40">
      <h1 className="text-xl font-bold tracking-tight text-gray-950">
        Appointments
      </h1>
      <div className="flex flex-col gap-y-8 mt-6">
        <TableAcceptedOffers />
      </div>
    </div>
  );
};

export default Appointments;
