import { FORM_APPLICATION_DATA, RESET_FORM_DATA } from "../action/formApplicationActions";

const initialState = {
  loan_amount: 20000,
  tenor_id: 6,
  main_purpose_id: null,
  full_name: "",
  date_of_birth: "",
  residency_id: null,
  nationality_id: null,
  email: "",
  phone_number: "",
  employment_status_id: null,
  monthly_income: null,
  nric_fin: "",
  civil_status_id: null,
  job_title_id: null,
  job_industry_id: null,
  address: "",
  unit: "",
  zip_code: "",
  housing_type_id: null,
  housing_status_id: null,
  length_of_stay_year_id: null,
  length_with_current_employer_id: null,
  length_with_previous_employer_id: null,
  is_own_property: true,
  is_have_existing_loan: true,
  existing_loan_amount_bank: null,
  existing_loan_amount_moneylender: null,
  total_existing_loan_amount: null,
  monthly_repayment_bank: null,
  monthly_repayment_moneylender: null,
  total_monthly_loan_repayment: null,
  is_agency_related: false,
};

const FormApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_APPLICATION_DATA:
      return (state = action.payload.data);
    case RESET_FORM_DATA:
      return (state = initialState);
    default:
      return state;
  }
};

export default FormApplicationReducer;
