export const FORM_APPLICATION_DATA = "FORM_APPLICATION_DATA";
export const RESET_FORM_DATA = "RESET_FORM_DATA";

export const setFormDataApplication = data => ({
    type: FORM_APPLICATION_DATA,
    payload: { data },
});

export const resetFormDataApplication = () => ({
    type: RESET_FORM_DATA,
});