import React, { useState } from "react";
import { NumericFormat } from "react-number-format";

const InputNumeric = ({ error, mandatory, ...props }) => {
  const [value, setValue] = useState("");
  return (
    <div>
      <label
        htmlFor={props.name}
        className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
      >
        {props.label}
        {mandatory && <span className="text-red-500">*</span>}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <NumericFormat
          placeholder={props.placeholder}
          value={value}
          prefix={"$"}
          thousandSeparator=","
          onValueChange={(vals) => setValue(vals.formattedValue)}
          className={`block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ${
            error ? "ring-red-500" : "ring-gray-300"
          }  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6`}
          {...props}
        />
      </div>
    </div>
  );
};

export default InputNumeric;
