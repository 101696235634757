import React from "react";
import TablePendingOffers from "./DashboardComponent/tablePendingOffers";
import TableCancelOffers from "./DashboardComponent/tableCancelOffers";

const OfferedPending = () => {
  return (
    <div className="mt-8 pb-40">
      <h1 className="text-xl font-bold tracking-tight text-gray-950">
        Offered
      </h1>
      <div className="flex flex-col gap-y-8 mt-6">
        <TablePendingOffers />
        <TableCancelOffers />
      </div>
    </div>
  );
};

export default OfferedPending;
