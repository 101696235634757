const TableHandle = ({
  loading,
  loadingComponent,
  data,
  dataComponent,
  colspan,
}) => {
  if (loading) {
    return loadingComponent;
  }

  if (!data.length) {
    return (
      <tbody className="divide-y divide-gray-200 bg-gray-50">
        <tr>
          <td colSpan={colspan} className="text-center py-20">
            <span className="text-xl font-bold">NO DATA</span>
          </td>
        </tr>
      </tbody>
    );
  }

  return dataComponent;
};

export default TableHandle;
