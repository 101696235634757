import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { JWT_ACCESS_TOKEN_KEY } from 'lib/network/jwt-token-access';

const WithAuthentication = (WrappedComponent) => {
    const AuthenticatedComponent = (props) => {
        const navigate = useNavigate();

        useEffect(() => {
            const token = localStorage.getItem(JWT_ACCESS_TOKEN_KEY);

            if (!token) {
                navigate('/login');
            }
        }, [navigate]);

        return <WrappedComponent {...props} />;
    };

    return AuthenticatedComponent;
};

export default WithAuthentication;