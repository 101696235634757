import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  IconBell,
  IconUserCircle,
  IconPower,
  IconX,
  IconMenu2,
} from "@tabler/icons-react";
import { LinkPageData } from "./DataMenu";
import { storeRemoveAccessToken } from "lib/network/jwt-token-access";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOutRequest } from "store/action/authActions";
import { fetchMasterData } from "store/action/masterDataActions";
// import { fetchProfile } from "store/action/profileActions";
import SidebarRight from "./SidebarRight";

const navigation = LinkPageData;
const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const HeaderPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openNotif, setOpenNotif] = useState(false);
  // const profileData = useSelector((state) => state.Profile);
  // const { data, loading } = profileData;

  useEffect(() => {
    // dispatch(fetchProfile());
    dispatch(fetchMasterData());
  }, [dispatch]);

  const logout = () => {
    dispatch(signOutRequest());
    storeRemoveAccessToken();
    navigate("/login");
  };

  return (
    <div>
      <Disclosure as="nav" className="bg-white shadow-md">
        {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <a href="/" className="-m-1.5 p-1.5">
                    <span className="sr-only">Your Company</span>
                    <img
                      className="w-[110px]"
                      src="/assets/loanly.webp"
                      alt=""
                    />
                  </a>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline gap-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={`${
                            item.href === location.pathname
                              ? "text-secondary font-medium"
                              : "text-black hover:text-secondary"
                          } rounded-md px-3 py-2 text-sm font-normal flex flex-row items-center gap-2`}
                          aria-current={item.href ? "page" : undefined}
                        >
                          {item.icon}
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <button
                      onClick={() => setOpenNotif(true)}
                      className="relative rounded-full bg-white p-1 text-secondary hover:text-secondary focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-secondary"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <IconBell className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.imageUrl}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-primary ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            <a
                              href="/profile"
                              className={`flex flex-row item-center gap-x-2 px-4 py-2 text-sm text-gray-700`}
                            >
                              <IconUserCircle className="w-5 h-5" />
                              Your Profile
                            </a>
                          </Menu.Item>
                          <Menu.Item>
                            <span
                              onClick={() => logout()}
                              className={`flex flex-row item-center gap-x-2 px-4 py-2 text-sm text-gray-700`}
                            >
                              <IconPower className="w-5 h-5" />
                              Sign out
                            </span>
                          </Menu.Item>
                          {/* {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={`flex flex-row item-center gap-x-2 ${
                                    active ? "bg-gray-100" : ""
                                  } block px-4 py-2 text-sm text-gray-700`}
                                >
                                  {item.icon}
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))} */}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white-800 p-2 text-primary focus:outline-none">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <IconX className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <IconMenu2 className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden relative h-screen pb-[64px] flex flex-col justify-between">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={`${
                      item.href === location.pathname
                        ? "text-secondary font-semibold"
                        : "text-black hover:text-secondary"
                    } block rounded-md px-3 py-2 text-base font-medium`}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-300 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-primary">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {user.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-primary hover:text-primary focus:outline-none focus:ring-1 focus:ring-primary focus:ring-offset-1 focus:ring-offset-primary"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <IconBell className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <SidebarRight open={openNotif} setOpen={setOpenNotif} />
    </div>
  );
};

export default HeaderPage;
