import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Please enter your email address'),
    password: Yup.string().min(8).required('Please enter your password'),
});

const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Please enter your name'),
    email: Yup.string().email('Invalid email').required('Please enter your email address'),
    password: Yup.string()
        .min(8).max(40, 'Password have a maximum of digits')
        .required('Please enter your password')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^=};{?.,:/@$'"+:)(<>!%*#?&])[A-Za-z\d^}={;?.,'"/:@+:$!<>)(%*#?&]{8,}$/,
            'Must contain minimum eight characters, at least one letter, one number and one special character',
        ),
    confirm_password: Yup.string()
        .min(8)
        .oneOf([Yup.ref('password')], 'Your passwords do not match')
        .required('Confirm password is required'),
    phone_number: Yup.string()
        .min(8, 'Phone number must be at least 8 digits')
        .max(14, 'Phone number can have a maximum of 14 digits')
        .matches(/^\d{8,14}$/, 'Phone number is not valid')
        .required('Please enter your phone number'),
});

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Please enter your email address'),
});

const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8)
        .required('Please enter your new password')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^=};{?.,:/@$'"+:)(<>!%*#?&])[A-Za-z\d^}={;?.,'"/:@+:$!<>)(%*#?&]{8,}$/,
            'Must contain minimum eight characters, at least one letter, one number and one special character',
        ),
    confirm_password: Yup.string()
        .min(8)
        .oneOf([Yup.ref('password')], 'Your passwords do not match')
        .required('Confirm password is required'),
});

export { LoginSchema, RegisterSchema, ForgotPasswordSchema, ResetPasswordSchema }