import React from "react";
import TableOffers from "./DashboardComponent/tableOffers";

const Dashboard = () => {
  return (
    <div className="mt-8 pb-40">
      <h1 className="text-xl font-bold tracking-tight text-gray-950">
        Dashboard
      </h1>
      <div className="flex flex-col gap-y-8 mt-6">
        <TableOffers />
      </div>
    </div>
  );
};

export default Dashboard;
