import React, { Fragment, useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { Dialog, Transition } from "@headlessui/react";
import { IconX } from "@tabler/icons-react";
import { API_URL, get } from "lib/network/api";
import findNameData from "lib/FindNameMasterData";
import InputShow from "components/InputShow";
import CurrencyFormatter from "lib/CurrencyFormatter";

const ModalDetails = ({ open, setOpen, id, masterData }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isChecked, setIsChecked] = useState(null);

  useEffect(() => {
    const fetchGetDetailLoan = () => {
      setLoading(true);
      get(`${API_URL}ldr/loan/applications/${id}`)
        .then((res) => {
          setData(res.data);
          setIsChecked(res.data.is_agency_related);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    if (id) {
      fetchGetDetailLoan();
    }
  }, [id]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white py-6 px-6 text-left shadow-xl transition-all my-8 w-full max-w-[896px]">
                <div className="flex flex-row justify-between">
                  <span className="text-lg font-semibold leading-6 text-gray-900">
                    User Filed
                  </span>
                  <div
                    onClick={() => setOpen(!open)}
                    className="cursor-pointer"
                  >
                    <IconX className="w-4 h-4" />
                  </div>
                </div>
                {loading ? (
                  <div className="grid grid-cols-2 gap-6 py-6">
                    {[...Array(8)].map((item, idx) => {
                      return (
                        <div key={idx}>
                          <Typography
                            key={idx}
                            as="div"
                            variant="h1"
                            className="h-[40px] rounded-sm  bg-gray-300"
                          >
                            &nbsp;
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-2 gap-6 py-6">
                      <InputShow
                        name="loan-purpose"
                        value={
                          data
                            ? `${findNameData(
                                masterData,
                                "purposes",
                                data?.main_purpose_id
                              )}`
                            : "-"
                        }
                        label="Loan purpose"
                      />
                      <InputShow
                        name="tenor"
                        value={
                          data
                            ? `${findNameData(
                                masterData,
                                "tenor",
                                data?.tenor_id
                              )}`
                            : "-"
                        }
                        label="Tenor Months"
                      />
                      <InputShow
                        name="amount"
                        value={
                          data
                            ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                data.loan_amount
                              )
                            : "-"
                        }
                        label="Loan Amount"
                      />
                      <div>
                        <label
                          htmlFor="self-employed"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Self employed
                        </label>
                        <div className="mt-2">
                          <input
                            id="self-employed"
                            aria-describedby="self-employed"
                            name="self-employed"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 disabled:ring-gray-200"
                            disabled
                            defaultChecked={false}
                          />
                        </div>
                      </div>
                      <InputShow
                        name="employment-statuses"
                        value={
                          data
                            ? `${findNameData(
                                masterData,
                                "employment-statuses",
                                data.employment_status_id
                              )}`
                            : "-"
                        }
                        label="Employment Status"
                      />
                      <InputShow
                        name="job-title"
                        value={
                          data
                            ? `${findNameData(
                                masterData,
                                "job-titles",
                                data.job_title_id
                              )}`
                            : "-"
                        }
                        label="Job Title"
                      />
                      <InputShow
                        name="job-industri"
                        value={
                          data
                            ? `${findNameData(
                                masterData,
                                "job-industries",
                                data.job_industry_id
                              )}`
                            : "-"
                        }
                        label="Job Industries"
                      />
                      <InputShow
                        name="length-with-current-employer"
                        value={
                          data
                            ? `${findNameData(
                                masterData,
                                "length-with-current-employer",
                                data.length_with_current_employer_id
                              )}`
                            : "-"
                        }
                        label="Length Current Job"
                      />
                      <InputShow
                        name="length-with-previous-employer"
                        value={
                          data
                            ? `${findNameData(
                                masterData,
                                "length-with-previous-employer",
                                data.length_with_previous_employer_id
                              )}`
                            : "-"
                        }
                        label="Length Previous Job"
                      />
                      <div>
                        <label
                          htmlFor="is_agency_related"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Talking to an agency regarding bankruptcy or debt
                        </label>
                        <div className="mt-2">
                          <input
                            name="is_agency_related"
                            id="is_agency_related"
                            autoComplete="is_agency_related"
                            defaultChecked={isChecked}
                            type="checkbox"
                            className={`h-4 w-4 rounded border-gray-300 text-gray-300 disabled:cursor-not-allowed disabled:bg-gray-300 hover:bg-gray-300`}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <h3 className="font-bold tracking-tight text-xl">
                      Existing Loans
                    </h3>
                    <div className="grid grid-cols-3 gap-6 pt-3 pb-6">
                      <InputShow
                        name="existing_loan_amount_bank"
                        value={
                          data
                            ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                data.existing_loan_amount_bank
                              )
                            : "-"
                        }
                        label="Bank Loan Amount"
                      />
                      <InputShow
                        name="existing_loan_amount_moneylender"
                        value={
                          data
                            ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                data.existing_loan_amount_moneylender
                              )
                            : "-"
                        }
                        label="Moneylender Loan Amount"
                      />
                      <InputShow
                        name="total_existing_loan_amount"
                        value={
                          data
                            ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                data.total_existing_loan_amount
                              )
                            : "-"
                        }
                        label="Total Existing Loans Amount"
                      />
                    </div>
                    <h3 className="font-bold tracking-tight text-xl">
                      Current Monthly Repayment
                    </h3>
                    <div className="grid grid-cols-3 gap-6 pt-3 pb-6">
                      <InputShow
                        name="monthly_repayment_bank"
                        value={
                          data
                            ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                data.monthly_repayment_bank
                              )
                            : "-"
                        }
                        label="Repayment Bank Loan"
                      />
                      <InputShow
                        name="monthly_repayment_moneylender"
                        value={
                          data
                            ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                data.monthly_repayment_moneylender
                              )
                            : "-"
                        }
                        label="Repayment Moneylender Loan"
                      />
                      <InputShow
                        name="total_monthly_loan_repayment"
                        value={
                          data
                            ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                data.total_monthly_loan_repayment
                              )
                            : "-"
                        }
                        label="Total Repayment Loans Amount"
                      />
                    </div>
                    {/* <div className="pb-6">
                      <div className="ring-1 ring-gray-200 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                        <div className="px-4 py-5 sm:px-6">
                          <h3 className=" text-base font-semibold leading-6 text-gray-900">
                            MoneyLender Loans
                          </h3>
                        </div>
                        <div className="px-4 py-5 sm:p-6">
                          <div className="grid grid-cols-2 gap-6">
                            <div>
                              <label
                                htmlFor="first-name"
                                className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
                              >
                                total ml loan amt
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  autoComplete="given-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="first-name"
                                className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
                              >
                                total ml mthly repayment
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  autoComplete="given-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="first-name"
                                className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
                              >
                                Moneylenders loans count
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  autoComplete="given-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="first-name"
                                className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
                              >
                                within a month
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  autoComplete="given-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <button
                      onClick={() => setOpen(!open)}
                      type="button"
                      className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border select-none focus-visible:outline-none"
                    >
                      Cancel
                    </button>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalDetails;
