import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const masterDataSlice = createSlice({
    name: 'masterData',
    initialState,
    reducers: {
        fetchStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchStart, fetchSuccess, fetchError } = masterDataSlice.actions;
export default masterDataSlice.reducer;
