import { Fragment, useState, useEffect } from "react";
import { Typography, Spinner } from "@material-tailwind/react";
import { Dialog, Transition } from "@headlessui/react";
import { API_URL, get, patch } from "lib/network/api";
import { IconX } from "@tabler/icons-react";
import InputShow from "components/InputShow";
import findNameData from "lib/FindNameMasterData";
import CurrencyFormatter from "lib/CurrencyFormatter";
import toast from "react-hot-toast";

const ModalUpdateStatus = ({ open, setOpen, id, masterData, status }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationCancel, setConfirmationCancel] = useState(false);
  const [loadingConfirmation, setLoadingConfirm] = useState(false);

  useEffect(() => {
    const fetchGetDetailLoan = () => {
      setLoading(true);
      get(`${API_URL}ldr/loan/offerings/${id}`)
        .then((res) => {
          setData(res.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    if (id) {
      fetchGetDetailLoan();
    }
  }, [id]);

  useEffect(() => {
    setConfirmationCancel(false);
  }, [open]);

  const onUpdateStatus = (paramsStatus) => {
    setLoadingConfirm(true);
    const payload = {
      status: paramsStatus,
    };

    patch(`${API_URL}ldr/loan/offerings/${id}`, payload)
      .then((res) => {
        setLoadingConfirm(false);
        setOpen(false);
        toast.success("Change Status Offer Success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.detail.error);
        setLoadingConfirm(false);
      });
  };

  const statusModal = {
    CANCELED: {
      background: "bg-orange-500",
      title: "Rescind Offer",
      subtitle: "Make some mistakes? cancel it, and make a new offer!",
      btn: "Rescind Offer",
      btnConfirm: "Yes Rescind Offer",
    },
    PENDING: {
      background: "bg-primary",
      title: "Change To Offer",
      subtitle: "Make status changes to make offers visible to the borrower",
      btn: "Offer Application",
      btnConfirm: "Yes Make Offer visible",
    },
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white py-6 px-6 text-left shadow-xl transition-all my-8 w-full max-w-[896px]">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold leading-6 text-gray-900">
                      {statusModal[status].title}
                    </span>
                    <span className="text-sm text-gray-500 mt-1">
                      {statusModal[status].subtitle}
                    </span>
                  </div>
                  <div
                    onClick={() => setOpen(!open)}
                    className="cursor-pointer"
                  >
                    <IconX className="w-4 h-4" />
                  </div>
                </div>
                <>
                  {loading ? (
                    <div className="grid grid-cols-2 gap-6 py-6">
                      {[...Array(8)].map((item, idx) => {
                        return (
                          <div key={idx}>
                            <Typography
                              key={idx}
                              as="div"
                              variant="h1"
                              className="h-[40px] rounded-sm  bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 gap-6 py-6">
                      <InputShow
                        name="full_name"
                        value={data ? data.loan_application.full_name : "-"}
                        label="Full Name"
                      />
                      <InputShow
                        name="phone_number"
                        value={data ? data.loan_application.phone_number : "-"}
                        label="Phone Number"
                      />
                      <InputShow
                        name="offer_loan"
                        value={
                          data
                            ? `${CurrencyFormatter("en-SG", "SGD", 0).format(
                                data.approved_loan_amount
                              )}`
                            : "-"
                        }
                        label="Offer Loan Amount"
                      />
                      <InputShow
                        name="tenor"
                        value={
                          data
                            ? `${findNameData(
                                masterData,
                                "tenor",
                                data?.tenor_id
                              )} Months`
                            : "-"
                        }
                        label="Offer Tenor"
                      />
                      <InputShow
                        name="monthly_cost"
                        value={
                          data
                            ? `${CurrencyFormatter("en-SG", "SGD", 0).format(
                                data.monthly_cost
                              )}`
                            : "-"
                        }
                        label="Monthly Cost"
                      />
                      <InputShow
                        name="monthly_rate"
                        value={data ? `${data.monthly_rate}%` : "-"}
                        label="Monthly Rate"
                      />
                    </div>
                  )}
                </>
                {confirmationCancel ? (
                  <div className="flex flex-row gap-3">
                    <button
                      onClick={() => onUpdateStatus(status)}
                      type="button"
                      className={`rounded-md ${statusModal[status].background} px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline-none`}
                    >
                      {loadingConfirmation ? (
                        <Spinner color="white" className="h-8 w-8" />
                      ) : (
                        <>{statusModal[status].btnConfirm}</>
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-row gap-3">
                    <button
                      onClick={() => setConfirmationCancel(!confirmationCancel)}
                      type="button"
                      className={`rounded-md ${statusModal[status].background} px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline-none`}
                    >
                      {statusModal[status].btn}
                    </button>
                    <button
                      onClick={() => setOpen(!open)}
                      type="button"
                      className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border focus-visible:outline-none"
                    >
                      Close
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalUpdateStatus;
