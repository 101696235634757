import InputShow from "components/InputShow";
import findNameData from "lib/FindNameMasterData";
import moment from "moment";

const PersonalComponent = ({ data, masterData }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <InputShow
        name="full-name"
        value={data ? data.full_name : "-"}
        label="Full Name"
      />
      <InputShow
        name="date-of-birth"
        value={data ? moment(data.date_of_birth).format("DD / MM / YYYY") : "-"}
        label="Date Of Birth"
      />
      <InputShow
        name="nationality"
        value={
          data
            ? `${
                data.nationality_id
                  ? findNameData(
                      masterData,
                      "nationalities",
                      data.nationality_id
                    )
                  : "-"
              }`
            : "-"
        }
        label="Nationality"
      />
      <InputShow
        name="residency"
        value={
          data
            ? `${
                data.residency_id
                  ? findNameData(masterData, "residencies", data.residency_id)
                  : "-"
              }`
            : "-"
        }
        label="Residency"
      />
      <InputShow
        name="civil-statuses"
        value={
          data
            ? `${findNameData(
                masterData,
                "civil-statuses",
                data.civil_status_id
              )}`
            : "-"
        }
        label="Civil Status"
      />
      <InputShow
        name="address"
        value={data ? data.address : "-"}
        label="Address"
      />
      <InputShow name="unit" value={data ? data.unit : "-"} label="Unit" />
      <InputShow
        name="zip_code"
        value={data ? data.zip_code : "-"}
        label="Zip Code"
      />
      <InputShow
        name="housing-types"
        value={
          data
            ? `${
                data.housing_type_id
                  ? findNameData(
                      masterData,
                      "housing-types",
                      data.housing_type_id
                    )
                  : "-"
              }`
            : "-"
        }
        label="Type Housing"
      />
      <InputShow
        name="housing-statuses"
        value={
          data
            ? `${
                data.housing_status_id
                  ? findNameData(
                      masterData,
                      "housing-statuses",
                      data.housing_status_id
                    )
                  : "-"
              }`
            : "-"
        }
        label="Housing Status"
      />
    </div>
  );
};

export default PersonalComponent;
