import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IconX } from "@tabler/icons-react";
import { API_URL, get } from "lib/network/api";
import { Spinner } from "@material-tailwind/react";
import PersonalComponent from "./personal";
// import CpfHousingWithdraw from "./cpfHousingWithdraw";
// import CpfContribution from "./cpfContribution";
// import Vehicles from "./vehicles";
// import Noa from "./noa";
// import HdbOwnership from "./hdbOwnership";
// import ChildBirth from "./childBirth";

const ModalInfo = ({ open, setOpen, id, masterData }) => {
  const [activeTab, setActiveTab] = useState("personal");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchGetDetailLoan = () => {
      setLoading(true);
      get(`${API_URL}ldr/loan/applications/${id}`)
        .then((res) => {
          setData(res.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    if (id) {
      fetchGetDetailLoan();
    }
  }, [id]);

  const dataTabs = [
    {
      label: "Personal",
      value: "personal",
      desc: `It really matters and then like it really doesn't matter.
          What matters is the people who are sparked by it. And the people 
          who are like offended by it, it doesn't matter.`,
      component: <PersonalComponent data={data} masterData={masterData} />,
    },
    // {
    //   label: "Cpf Housing Withdrawal",
    //   value: "housing_withdraw",
    //   desc: `Because it's about motivating the doers. Because I'm here
    //       to follow my dreams and inspire other people to follow their dreams, too.`,
    //   component: <CpfHousingWithdraw />,
    // },
    // {
    //   label: "Cpf Contributions",
    //   value: "contributions",
    //   desc: `We're not always in the position that we want to be at.
    //       We're constantly growing. We're constantly making mistakes. We're
    //       constantly trying to express ourselves and actualize our dreams.`,
    //   component: <CpfContribution />,
    // },
    // {
    //   label: "Vehicles",
    //   value: "vehicles",
    //   desc: `Because it's about motivating the doers. Because I'm here
    //       to follow my dreams and inspire other people to follow their dreams, too.`,
    //   component: <Vehicles />,
    // },
    // {
    //   label: "Hdb Ownerships",
    //   value: "hdb_ownerships",
    //   desc: `We're not always in the position that we want to be at.
    //       We're constantly growing. We're constantly making mistakes. We're
    //       constantly trying to express ourselves and actualize our dreams.`,
    //   component: <HdbOwnership />,
    // },
    // {
    //   label: "NOA",
    //   value: "noa",
    //   desc: `We're not always in the position that we want to be at.
    //         We're constantly growing. We're constantly making mistakes. We're
    //         constantly trying to express ourselves and actualize our dreams.`,
    //   component: <Noa />,
    // },
    // {
    //   label: "Child Birth Records",
    //   value: "child_birth_records",
    //   desc: `We're not always in the position that we want to be at.
    //         We're constantly growing. We're constantly making mistakes. We're
    //         constantly trying to express ourselves and actualize our dreams.`,
    //   component: <ChildBirth />,
    // },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white py-6 px-6 text-left shadow-xl transition-all my-8 w-full max-w-[896px]">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold leading-6 text-gray-900">
                      Info
                    </span>
                    <span className="text-sm text-gray-500 mt-1">
                      Interested? Then close this modal and make an offer!
                    </span>
                  </div>
                  <div
                    onClick={() => setOpen(!open)}
                    className="cursor-pointer"
                  >
                    <IconX className="w-4 h-4" />
                  </div>
                </div>
                {loading ? (
                  <div className="divide-y border border-gray-200 overflow-hidden rounded-lg bg-white my-6 h-[300px] flex justify-center items-center">
                    <Spinner color="blue" size="lg" className="h-10 w-10" />
                  </div>
                ) : (
                  <div className="divide-y border border-gray-200 overflow-hidden rounded-lg bg-white my-6">
                    <div className="flex flex-row justify-between gap-1 items-center py-2.5 px-3">
                      {dataTabs.map(({ label, value }) => (
                        <div
                          key={value}
                          value={value}
                          className={`
                          ${
                            activeTab === value
                              ? "text-primary bg-gray-50"
                              : "text-dark-gray"
                          } py-2 cursor-pointer px-3 text-center font-medium text-sm rounded-lg
                        `}
                          onClick={() => setActiveTab(value)}
                        >
                          {label}
                        </div>
                      ))}
                    </div>
                    <div>
                      {dataTabs.map(
                        ({ value, component }) =>
                          activeTab === value && (
                            <div key={value} className="p-6">
                              {component}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                )}

                <button
                  onClick={() => setOpen(!open)}
                  type="button"
                  className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border select-none focus-visible:outline-none"
                >
                  Cancel
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalInfo;
