import axios from "axios";
import {
  JWT_ACCESS_TOKEN_KEY,
  storeRemoveAccessToken,
} from "./jwt-token-access";
import getAccessToken from "./jwt-token-access";

//apply base url for axios
export const API_URL = process.env.REACT_APP_URL_STAGING;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

function redirectToLogin() {
  storeRemoveAccessToken();
  window.location.replace("/login");
}

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Error 401 (Unauthorized), arahkan ke halaman login
      redirectToLogin();
    }
    return Promise.reject(error);
  }
);

function addBearerToken(config) {
  if (!localStorage.getItem(JWT_ACCESS_TOKEN_KEY)) {
    return config;
  }

  if ("headers" in config) {
    config["headers"] = {
      ...config["headers"],
      Authorization: getAccessToken(),
    };
  } else {
    config = {
      headers: {
        Authorization: getAccessToken(),
      },
    };
  }

  return config;
}

export const handleErrorRequest = (error) => {
  if (error.detail) {
    return error.detail;
  } else if (error.response) {
    if (error.response.data.errors) {
      let errors = error.response.data.errors.map((error) => {
        return error.detail;
      });

      if (errors.length > 0) {
        return errors.join("\n");
      }
    }
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return error.response.data;
  } else if (error.request) {
    if (error.request.status === 404) {
      return error.request.statusText;
    }

    if (error.request.status === 0) {
      return "Internal Server Error";
    }
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return error.request.statusText;
  }

  // Something happened in setting up the request that triggered an Error
  return "Internal Server Error";
};

export async function get(url, config = {}) {
  config = addBearerToken(config);
  return await axiosApi
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function post(url, data, config = {}) {
  config = addBearerToken(config);

  return axiosApi
    .post(url, { ...data }, { ...config })

    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function put(url, data, config = {}) {
  config = addBearerToken(config);
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function del(url, config = {}) {
  config = addBearerToken(config);
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function patch(url, data, config = {}) {
  config = addBearerToken(config);
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function uploadFile(url, file, name) {
  var formData = new FormData();
  formData.append(name, file);
  return await axiosApi
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getAccessToken(),
      },
    })
    .then((response) => response.data)
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function putUploadFile(url, file, name) {
  var formData = new FormData();
  formData.append(name, file);
  return await axiosApi
    .put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getAccessToken(),
      },
    })
    .then((response) => response.data)
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function uploadFormData(url, data) {
  return await axiosApi
    .post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getAccessToken(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}

export async function getDownload(url) {
  return await axiosApi
    .get(url, {
      responseType: "blob",
      headers: {
        Authorization: getAccessToken(),
      },
    })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      let errorDetail = {
        detail: handleErrorRequest(error),
        status_code: error.request.status,
      };
      throw errorDetail;
    });
}
