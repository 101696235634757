import React, { useState, useEffect } from "react";
import HeaderPage from "./HeaderPage";

const FullLayoutHome = ({ bgHeader, children }) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex-1 flex-col flex">
      <div
        className={`fixed top-0 left-0 w-full duration-[600ms] z-10 ${
          isSticky ? "shadow-sm" : ""
        } ${bgHeader ? bgHeader : "bg-gray-bg"}`}
      >
        <HeaderPage />
      </div>
      <main>
        <div className="mx-auto w-full max-w-full pt-[80px] px-6 lg:px-8 h-screen">
          {children}
        </div>
      </main>
    </div>
  );
};

export default FullLayoutHome;
