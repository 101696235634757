import { combineReducers } from "redux";
import MasterDataReducer from "./reducer/masterDataReducers";
import FormApplicationReducer from "./reducer/formApplicationReducers";
import AuthReducer from './reducer/authReducers';
import ProfileReducer from "./reducer/profileReducers";
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  AuthReducer: AuthReducer,
  MasterData: MasterDataReducer,
  FormApplication: FormApplicationReducer,
  Profile: ProfileReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_REQUEST') {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};


export default rootReducer;