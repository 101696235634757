import { Fragment, useState, useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { API_URL, get, put, patch, post } from "lib/network/api";
import { Dialog, Transition } from "@headlessui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import findNameData from "lib/FindNameMasterData";
import CurrencyFormatter from "lib/CurrencyFormatter";
import InputNumeric from "components/InputNumeric";
import { IconX } from "@tabler/icons-react";
import { NumericFormat } from "react-number-format";
import InputShow from "components/InputShow";
import toast from "react-hot-toast";
import moment from "moment";

const ModalFinalize = ({ open, setOpen, id, masterData }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [monthlyCost, setMonthlyCost] = useState(0);
  const [showUpdate, setShowUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCalculation, setLoadingCalculation] = useState(false);
  const [updateStatusButton, setUpdateStatusButton] = useState("");
  const [offerData, setOfferData] = useState({
    approved_loan_amount: null,
    tenor_id: 0,
    monthly_rate: null,
  });

  const validationSchema = Yup.object().shape({
    approved_loan_amount: Yup.number()
      .min(1000, "Loan Offer amount must be at least $1,000")
      .max(250000, "Loan Offer amount cannot exceed $250,000")
      .required("Loan Offer Amount is required"),
    tenor_id: Yup.number().required("Tenor ID is required"),
    monthly_rate: Yup.number().required("Monthly Rate is required"),
  });

  useEffect(() => {
    const fetchGetDetailLoan = () => {
      setLoading(true);
      get(`${API_URL}ldr/loan/offerings/${id}`)
        .then((res) => {
          setData(res.data);
          setOfferData({
            approved_loan_amount: res.data.approved_loan_amount,
            tenor_id: res.data.tenor_id,
            monthly_rate: res.data.monthly_rate,
          });
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    if (id) {
      fetchGetDetailLoan();
    }
  }, [id]);

  const handleOnSubmit = async (values) => {
    setLoadingCalculation(true);

    const payload = {
      approved_loan_amount: values.approved_loan_amount,
      tenor_id: values.tenor_id,
      monthly_rate: values.monthly_rate,
    };

    post(`${API_URL}ldr/loan/calculate-monthly-cost`, payload)
      .then((res) => {
        setMonthlyCost(res.data.monthly_cost);
        setLoadingCalculation(false);
      })
      .catch((error) => {
        toast.error(error.detail.error);
        setLoadingCalculation(false);
      });
  };

  const handleUpdateOffer = async () => {
    setLoadingUpdate(true);
    const payload = {
      approved_loan_amount: offerData.approved_loan_amount,
      tenor_id: offerData.tenor_id,
      monthly_cost: monthlyCost,
      monthly_rate: offerData.monthly_rate,
      processing_fee: 10,
    };

    put(`${API_URL}ldr/loan/offerings/${id}`, payload)
      .then((res) => {
        setLoadingUpdate(false);
        setShowUpdate(!showUpdate);
        toast.success("Update Offer Success");
      })
      .catch((error) => {
        toast.error(error.detail.error);
        setLoadingUpdate(false);
      });
  };

  const onUpdateStatus = (paramsStatus) => {
    setLoadingConfirm(true);
    const payload = {
      status: paramsStatus,
    };

    patch(`${API_URL}ldr/loan/offerings/${id}`, payload)
      .then((res) => {
        setLoadingConfirm(false);
        setOpen(false);
        toast.success(
          `${
            paramsStatus === "COMPLETED" ? "Finalize" : "Rescind"
          } Offer Success`
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.detail.error);
        setLoadingConfirm(false);
      });
  };

  const handleValueChange = (handleChange, name, value) => {
    setOfferData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleChange(name, value);
  };

  const onSelect = (handleChange, name, value) => {
    const convertString = parseInt(value);
    handleChange(name, convertString);
    setOfferData((prevState) => ({
      ...prevState,
      tenor_id: convertString,
    }));
  };

  const onShowUpdate = () => {
    setShowUpdate(!showUpdate);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(true)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={() => setOpen(true)}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white py-6 px-6 text-left shadow-xl transition-all my-8 w-full max-w-[896px]">
                <div className="flex flex-row justify-between border-b pb-5">
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold leading-6 text-gray-900">
                      Finalise Loan Result
                    </span>
                    <p className="text-sm text-gray-600 mt-1">
                      If client decline or you rescind the loan after office
                      interview,{" "}
                      <strong>state the terms you are planning to offer</strong>
                    </p>
                  </div>
                  <div
                    onClick={() => setOpen(!open)}
                    className="cursor-pointer"
                  >
                    <IconX className="w-4 h-4" />
                  </div>
                </div>
                <div className="mt-4 text-sm font-medium text-gray-500">
                  {data?.appointment_date ? (
                    <>
                      Appointment Date:{"  "}
                      <span className="text-sm font-normal text-primary">
                        {moment(data.appointment_date).format(
                          "MMM DD, YYYY hh:mm"
                        )}
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
                {showUpdate ? (
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      approved_loan_amount: offerData
                        ? offerData.approved_loan_amount
                        : 0,
                      tenor_id: offerData ? offerData.tenor_id : 0,
                      monthly_rate: offerData ? offerData.monthly_rate : 5,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      handleOnSubmit(values);
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        handleSubmit,
                      } = props;
                      return (
                        <form
                          className="flex flex-col gap-y-4 pt-6"
                          action="#"
                          onSubmit={handleSubmit}
                        >
                          <div>
                            <div className="flex flex-col">
                              <span className="text-lg font-semibold leading-6 text-gray-900">
                                Update Offer Loan
                              </span>
                              <p className="text-sm text-gray-600 mt-1">
                                After an interview at the office, a new
                                agreement was reached
                              </p>
                            </div>
                          </div>
                          <div>
                            <InputNumeric
                              name="approved_loan_amount"
                              label="Loan Offer Amount"
                              placeholder="Loan Offer Amount"
                              value={values.approved_loan_amount}
                              mandatory
                              onValueChange={(val) =>
                                handleValueChange(
                                  setFieldValue,
                                  "approved_loan_amount",
                                  val.floatValue
                                )
                              }
                              error={Boolean(
                                errors["approved_loan_amount"] &&
                                  touched["approved_loan_amount"]
                              )}
                            />
                            {errors["approved_loan_amount"] &&
                              touched["approved_loan_amount"] && (
                                <div className="text-red-600 mt-1 text-sm">
                                  {errors["approved_loan_amount"]}
                                </div>
                              )}
                          </div>
                          <div>
                            <label
                              htmlFor="monthly_rate"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Interest
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <NumericFormat
                                id="monthly_rate"
                                name="monthly_rate"
                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                                placeholder="0.00"
                                value={values.monthly_rate}
                                onValueChange={(val) =>
                                  handleValueChange(
                                    setFieldValue,
                                    "monthly_rate",
                                    val.floatValue
                                  )
                                }
                                suffix="%"
                                aria-describedby="monthly_rate"
                              />
                              {errors["monthly_rate"] &&
                                touched["monthly_rate"] && (
                                  <div className="text-red-600 mt-1 text-sm">
                                    {errors["monthly_rate"]}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="first-name"
                              className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
                            >
                              Tenor<span className="text-red-500">*</span>
                            </label>
                            <div className="mt-2">
                              <select
                                id="tenor_id"
                                name="tenor_id"
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
                                value={values.tenor_id}
                                onChange={(e) =>
                                  onSelect(
                                    setFieldValue,
                                    "tenor_id",
                                    e.target.value
                                  )
                                }
                              >
                                {masterData.tenor.map((item, idx) => {
                                  return (
                                    <option key={idx} value={item.id}>
                                      {item.name} Months
                                    </option>
                                  );
                                })}
                              </select>
                              {errors["tenor_id"] && touched["tenor_id"] && (
                                <div className="text-red-600 mt-1 text-sm">
                                  {errors["tenor_id"]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="rounded-md bg-white p-3 text-sm font-semibold text-primary border border-primary shadow-sm"
                            >
                              {loadingCalculation ? (
                                <Spinner className="h-7 w-7" color="white" />
                              ) : (
                                "Calculate Monthly Cost"
                              )}
                            </button>
                          </div>
                          <div className="border-t border-gray-400 my-2" />
                          <InputShow
                            name="monthly_cost"
                            value={
                              monthlyCost
                                ? CurrencyFormatter("en-SG", "SGD", 0).format(
                                    monthlyCost
                                  )
                                : "Calculate Monthly Cost First"
                            }
                            label="Monthly Cost"
                          />
                          <div className="flex flex-row gap-3">
                            <button
                              disabled={loadingUpdate}
                              onClick={() => handleUpdateOffer()}
                              className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm"
                            >
                              {loadingUpdate ? (
                                <Spinner className="h-7 w-7" color="white" />
                              ) : (
                                "Update Offer"
                              )}
                            </button>
                            <button
                              onClick={() => onShowUpdate()}
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border focus-visible:outline-none"
                            >
                              Cancel Update Offer
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                ) : (
                  <>
                    {loading ? (
                      <div className="divide-y border border-gray-200 overflow-hidden rounded-lg bg-white my-6 h-[300px] flex justify-center items-center">
                        <Spinner color="blue" size="lg" className="h-10 w-10" />
                      </div>
                    ) : (
                      <div className="grid grid-cols-2 gap-6 py-6">
                        <InputShow
                          name="full_name"
                          value={data ? data.loan_application.full_name : "-"}
                          label="Full Name"
                        />
                        <InputShow
                          name="phone_number"
                          value={
                            data ? data.loan_application.phone_number : "-"
                          }
                          label="Phone Number"
                        />
                        <InputShow
                          name="offer_loan"
                          value={
                            offerData
                              ? `${CurrencyFormatter("en-SG", "SGD", 0).format(
                                  offerData.approved_loan_amount
                                )}`
                              : "-"
                          }
                          label="Offer Loan Amount"
                        />
                        <InputShow
                          name="tenor"
                          value={
                            offerData
                              ? `${findNameData(
                                  masterData,
                                  "tenor",
                                  offerData?.tenor_id
                                )} Months`
                              : "-"
                          }
                          label="Offer Tenor"
                        />
                        <InputShow
                          name="monthly_cost"
                          value={
                            data
                              ? `${CurrencyFormatter("en-SG", "SGD", 0).format(
                                  data.monthly_cost
                                )}`
                              : "-"
                          }
                          label="Monthly Cost"
                        />
                        <InputShow
                          name="monthly_rate"
                          value={data ? `${offerData.monthly_rate}%` : "-"}
                          label="Interest"
                        />
                      </div>
                    )}
                    {updateStatusButton === "" && (
                      <div className="flex flex-row gap-3">
                        <button
                          onClick={() => onShowUpdate()}
                          className="rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline-none"
                        >
                          Update Offer
                        </button>
                        <button
                          onClick={() => setUpdateStatusButton("COMPLETED")}
                          className="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline-none"
                        >
                          Finalize Offer
                        </button>
                        <button
                          onClick={() => setUpdateStatusButton("CANCELED")}
                          className="rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm border focus-visible:outline-none"
                        >
                          Rescind Offer
                        </button>
                      </div>
                    )}

                    {updateStatusButton === "COMPLETED" && (
                      <div className="flex flex-row gap-3">
                        <button
                          disabled={loadingConfirm}
                          onClick={() => onUpdateStatus("COMPLETED")}
                          className="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline-none"
                        >
                          {loadingConfirm ? (
                            <Spinner className="h-7 w-7" color="white" />
                          ) : (
                            "Confirm Finalize"
                          )}
                        </button>
                        <button
                          disabled={loadingConfirm}
                          onClick={() => setUpdateStatusButton("")}
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border focus-visible:outline-none"
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {updateStatusButton === "CANCELED" && (
                      <div className="flex flex-row gap-3">
                        <button
                          disabled={loadingConfirm}
                          onClick={() => onUpdateStatus("CANCELED")}
                          className="rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline-none"
                        >
                          {loadingConfirm ? (
                            <Spinner className="h-7 w-7" color="white" />
                          ) : (
                            "Confirm Rescind"
                          )}
                        </button>
                        <button
                          disabled={loadingConfirm}
                          onClick={() => setUpdateStatusButton("")}
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border focus-visible:outline-none"
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalFinalize;
